import { graphql, useStaticQuery } from 'gatsby'

const useLookieLookie = () => {
  const { lookieLookie } = useStaticQuery(graphql`
    query lookieLookieQuery {
      lookieLookie: allFile(
        filter: { relativePath: { eq: "lookie-lookie.png" } }
      ) {
        nodes {
          childImageSharp {
            fixed(width: 931) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  `)

  return lookieLookie
}

export default useLookieLookie
