import React from 'react'
import withPreview from '../components/Preview/withPreview'
import { PREVIEW_QUERY } from '../components/Preview/preview.queries'
import {
  formatBranch,
  formatPest,
  formatPost,
  formatCommercialIndustry,
} from '../components/Preview/utils'
import 'twin.macro'
import { H1 } from '../components/Headings'
import Container from '../components/Container'
import PostTemplate from '../templates/post'
import PestTemplate from '../templates/pest-template'
import BranchTemplate from '../templates/service_branch'
import EmployeeTraining from '../templates/page/employee-training'
import FeaturedTopic from '../templates/page/featured-topic'
import CookMuseum from '../templates/page/cook-museum-of-natural-science'
import PestControlServices from '../templates/page/pest-control-services'
import About from '../templates/page/about'
import SpecializedPestServices from '../templates/page/specialized-pest-services'
import CookieTheCop from '../templates/page/cookie-the-cop'
import Default from '../templates/page/default'
import History from '../templates/page/history'
import HomeExplorer from '../templates/page/home-explorer'
import BuilderServices from '../templates/page/builder-services'
import ResidentialQuote from '../templates/page/residential-quote'
import CommercialQuote from '../templates/page/commercial-quote'
import TermiteServices from '../templates/page/termite-services'
import CommercialTermiteServices from '../templates/page/commercial-termite-services'
import IndexPage from '../pages/index'
import CommercialTemplate from '../pages/commercial/[industry]/[subindustry]'

const RoutePreview = props => {
  const templateComponents = {
    'Featured Topic': FeaturedTopic,
    'Employee Training': EmployeeTraining,
    'Cook Museum Of Natural Science': CookMuseum,
    'Pest Control Services': PestControlServices,
    'Specialized Pest Services': SpecializedPestServices,
    'Cookie The Cop': CookieTheCop,
    'Home Explorer': HomeExplorer,
    'Builder Services': BuilderServices,
    'Residential Quote': ResidentialQuote,
    'Commercial Quote': CommercialQuote,
    'Termite Services': TermiteServices,
    'Commercial Termite Services': CommercialTermiteServices,
    'Commercial Landing Page': CommercialTemplate,
    About,
    Default,
    History,
  }

  const previewData = {}

  let Component = () => <></>

  if (props?.previewData?.pest) {
    Object.assign(previewData, formatPest(props.previewData.pest))
    return (
      <PestTemplate preview={formatPest(props.previewData.pest)} {...props} />
    )
  } else if (props?.previewData?.post) {
    Object.assign(previewData, formatPost(props.previewData.post))
    return (
      <PostTemplate preview={formatPost(props.previewData.post)} {...props} />
    )
  } else if (props.previewData?.serviceArea || props.previewData?.branch) {
    const branchData =
      props?.previewData?.serviceArea ?? props?.previewData?.branch
    Object.assign(previewData, formatBranch(branchData))
    return <BranchTemplate preview={formatBranch(branchData)} {...props} />
  } else if (props?.previewData?.commercialIndustry) {
    const commercialIndustryData = props?.previewData?.commercialIndustry
    Object.assign(previewData, formatCommercialIndustry(commercialIndustryData))
    Component = () => (
      <CommercialTemplate
        preview={formatCommercialIndustry(commercialIndustryData)}
        {...props}
      />
    )
  } else if (props?.previewData?.page?.isFrontPage) {
    Object.assign(previewData, props?.previewData)
    const SelectedTemplate = IndexPage
    Component = () => (
      <SelectedTemplate preview={props.previewData} {...props} />
    )
  } else if (
    props?.previewData?.page?.template?.templateName &&
    props?.previewData?.page?.template?.templateName in templateComponents
  ) {
    Object.assign(previewData, props?.previewData?.page)
    const SelectedTemplate =
      templateComponents[props?.previewData?.page?.template?.templateName]
    Component = () => (
      <SelectedTemplate preview={props.previewData?.page} {...props} />
    )
  }

  return (
    <>
      <Component />
      <section tw="bg-gray-900 py-20" w-full>
        <Container>
          <H1 tw="text-white pt-10 pb-5">Here's the returned PreviewData</H1>
          <div tw="text-white bg-blue-900 my-10 p-5 rounded-md border-blue-800 border-2 overflow-x-hidden hover:(overflow-x-scroll)">
            <pre>{JSON.stringify({ previewData, props }, null, 2)}</pre>
          </div>
        </Container>
      </section>
    </>
  )
}

export default withPreview({ preview: PREVIEW_QUERY })(RoutePreview)
