import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import Form from '../../components/Form'

import { useFormData } from '../../hooks/gravityForms'
import { H1 } from '../../components/Headings'
import Container from '../../components/Container'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../../helpers'

const PageTemplate = ({ data, location, preview }) => {
  const page = preview ? preview : data.allWpPage.nodes[0]

  const allFormData = useFormData()

  const handleError = ({ values, error, reset }) => {
    //handle error
  }

  const handleSuccess = ({ values, reset, confirmations }) => {
    //handle success
  }

  return (
    <Layout location={location}>
      {!preview && <Seo post={formatYoastSeo(page)} />}
      <div tw="w-full bg-gradient-to-b from-blue-100 via-white to-white">
        <Container tw="md:(grid grid-cols-12 col-gap-8 py-12)">
          <div tw="col-span-8">
            <H1 tw="pt-6 text-center sm:(text-left)">{page.title}</H1>
            <div
              tw="text-center sm:(text-left) mb-12 prose max-w-full"
              dangerouslySetInnerHTML={{ __html: page.content }}
            />
            <Form
              id={1}
              allFormData={allFormData}
              successCallback={handleSuccess}
              errorCallback={handleError}
            />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
        seo {
          ...PostSEO
        }
      }
    }
  }
`
