// strip out null values
const condenseObjectList = objectList =>
  Object.assign(
    {},
    ...objectList.reverse().map(listItem => {
      const newItem = {}
      Object.keys(listItem).forEach(key => {
        if (listItem[key] !== null) {
          newItem[key] = listItem[key]
        }
      })
      return newItem
    })
  )

export const formatBranch = previewData => {
  const { id, slug, title, content } = previewData

  // handle if the data is a service area rather than a branch
  const { branchMeta, branchTypes } = previewData?.serviceAreaMeta
    ? previewData.serviceAreaMeta.serviceAreaBranch[0]
    : previewData

  const serviceAreas = Array.isArray(branchMeta?.serviceAreas)
    ? branchMeta.serviceAreas.map(node => node.title)
    : null

  const branchTypeSlugs = Array.isArray(branchTypes?.nodes)
    ? branchTypes.nodes.map(node => node.slug)
    : null

  const branchPreview = {
    id,
    slug,
    title,
    content,
    featuredImage: previewData.featuredImage
      ? previewData.featuredImage.node.sourceUrl
      : null,
    branchTitle: title,
    ...branchMeta,
    serviceAreas,
    branchTypes: branchTypeSlugs,
  }
  const revision =
    Array.isArray(previewData?.revisions?.nodes) &&
    previewData?.revisions.nodes.length > 0
      ? formatBranch(condenseObjectList(previewData.revisions.nodes))
      : null

  if (revision) {
    Object.keys(branchPreview).forEach(key => {
      if (!key in revision || revision[key] === null) {
        revision[key] = branchPreview[key]
      }
    })
    if (!'branchMeta' in revision) {
      Object.apply(revision, { branchMeta: {} })
    }
    Object.keys(branchMeta).forEach(key => {
      if (
        (!revision.branchMeta || revision?.branchMeta[key] == null) &&
        branchMeta[key] != null
      ) {
        try {
          revision.branchMeta[key] = branchMeta[key]
        } catch (e) {
          console.log(e)
        }
      }
    })
  }

  // format as an object matching the context on `service_branch.js`.
  return {
    ...branchPreview,
    revision,
  }
}

export const formatPest = previewData => {
  const pest = previewData

  // recurrision on revisions nodes. should only go a single level.
  const revision =
    Array.isArray(previewData?.revisions?.nodes) &&
    previewData?.revisions.nodes.length > 0
      ? formatPest(condenseObjectList(previewData.revisions.nodes))
      : null

  if (revision) {
    Object.keys(pest).forEach(key => {
      if (!key in revision || (revision[key] === null && pest[key] !== null)) {
        revision[key] = pest[key]
      }
    })

    if (!'pestDetails' in revision) {
      revision.pestDetails = {}
    }
    Object.keys(pest.pestDetails).forEach(key => {
      if (
        revision?.pestDetails[key] === null &&
        pest.pestDetails[key] !== null
      ) {
        revision.pestDetails[key] = pest.pestDetails[key]
      }
    })
    if (!Array.isArray(revision?.pestTypeDetails?.nodes)) {
      revision.pestTypeDetails = pest.pestTypeDetails
    }
  }

  return {
    ...pest,
    revision,
  }
}

export const formatPost = previewData => {
  const post = previewData

  const revision =
    Array.isArray(previewData?.revisions?.nodes) &&
    previewData?.revisions.nodes.length > 0
      ? formatPost(condenseObjectList(previewData.revisions.nodes))
      : null

  if (revision) {
    Object.keys(post).forEach(key => {
      if (!key in revision || revision[key] === null) {
        revision[key] = post[key]
      }
    })
  }

  return {
    ...post,
    revision,
  }
}

export const formatCommercialIndustry = previewData => {
  const industry = {
    slug: previewData?.slug,
    title: previewData?.title,
    id: previewData?.id,
    featuredImage: previewData?.featuredImage,
    ...previewData.commercialIndustries,
  }

  const subIndustries = industry.subIndustries
    .map(subIndustry => ({
      ...subIndustry,
      industry: previewData.title,
      industryId: industry.id,
    }))
    .flat()

  return { industries: [industry], subIndustries }
}
