import { gql } from 'apollo-boost'

export const PREVIEW_QUERY = gql`
  query previewQuery($id: ID!) {
    post(id: $id, idType: DATABASE_ID) {
      ...PostPreview
      revisions {
        nodes {
          ...PostPreview
        }
      }
    }
    # PEST QUERY
    pest(id: $id, idType: DATABASE_ID) {
      ...PestPreview
      revisions {
        nodes {
          ...PestPreview
        }
      }
    }
    branch(id: $id, idType: DATABASE_ID) {
      ...BranchPreview
      revisions {
        nodes {
          ...BranchPreview
        }
      }
    }

    serviceArea(id: $id, idType: DATABASE_ID) {
      ...ServiceAreaPreview
      revisions {
        nodes {
          ...ServiceAreaPreview
        }
      }
    }
    page(id: $id, idType: DATABASE_ID) {
      isFrontPage
      template {
        templateName
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      ...EmployeeTraining
      ...HeroSection
      ...AdvancedContentSection
      ...FeaturedSection
      ...ContentCards
      ...CooksGuarentee
      ...FaqSection
      ...CallToAction
      ...CookMuseum
      ...PestControlServices
      ...About
      ...SpecializedPestServices
      ...CookieTheCop
      ...CompanyHistory
      ...HomeExplorer
      ...BuilderServices
      ...ResidentialTermiteServices
      ...CommercialTermiteServices
      ...HomePage
      ...CommercialServices

      title
      content
    }
    blog: posts(first: 1, where: { orderby: { field: DATE, order: DESC } }) {
      nodes {
        date
        excerpt
        title
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    commercialIndustry(id: $id, idType: DATABASE_ID) {
      ...CommercialIndustryPreview
    }
  }
  fragment PostPreview on Post {
    id
    content
    postCategories: categories {
      nodes {
        id
        slug
        name
      }
    }
    date
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
  }
  fragment PestPreview on Pest {
    id
    title
    featuredImage {
      node {
        sourceUrl
      }
    }
    pestDetails: pests {
      prevention
      fieldGroupName
      genus
      habits
      introduction
    }
    pestTypeDetails: pestTypes {
      nodes {
        description
        slug
        name
      }
    }
  }
  fragment BranchPreview on Branch {
    id
    title
    slug
    uri
    content
    featuredImage {
      node {
        id
        sourceUrl
      }
    }
    branchMeta {
      serviceAreas: branchServiceAreas {
        ... on ServiceArea {
          id
          title
        }
      }
      ...BranchLocationInfo
    }
  }
  fragment ServiceAreaPreview on ServiceArea {
    id
    title
    slug
    uri
    content
    featuredImage {
      node {
        id
        sourceUrl
      }
    }
    serviceAreaMeta {
      serviceAreaBranch {
        ... on Branch {
          ...BranchPreview
        }
      }
    }
  }
  fragment BranchLocationInfo on Branch_Branchmeta {
    careerCode: branchCareerCode
    officeAddress: branchOfficeAddress
    officeCity: branchOfficeCity
    officeState: branchOfficeState
    officeZip: branchOfficeZip
    officeEmailAddress: branchOfficeEmailAddress
    officePhone: branchOfficePhone
    officeHours: branchOfficeHours
    careerCodes: branchCareerCode
  }

  fragment HeroSection on Page {
    heroSection {
      content {
        headline
        subHeadline
        content
        image {
          id
          sourceUrl
          relativePath
        }
      }
      ctaButton {
        buttonText
        linkType: heroLinkType
        buttonLink {
          ... on Page {
            uri
          }
        }
        externalLink: heroExternalLink {
          target
          title
          url
        }
        buttonColor
        buttonOutline
        buttonTextColor
        addChevron
      }
      background {
        backgroundImage {
          relativePath
          sourceUrl
        }
        backgroundColor
        colorOverlay
        textColor
      }
    }
  }

  fragment AdvancedContentSection on Page {
    advancedContentSection {
      sections: advancedContentSections {
        appearance: advancedContentSectionAppearance {
          backgroundColor: advancedContentSectionBackgroundColor
          columnRatio: advancedContentSectionColumnRatio
          headlineColor: advancedContentSectionHeadlineColor
          textColor: advancedContentSectionTextColor
          buttonColor: advancedContentSectionButtonColor
          buttonTextColor: advancedContentSectionButtonTextColor
          cardAppearance: advancedContentSectionCardAppearance {
            backgroundColor: advancedContentCardBackgroundColor
            headlineColor: advancedContentCardHeadlineColor
            outlineColor: advancedContentCardOutlineColor
            textColor: advancedContentCardTextColor
          }
        }
        content: advancedContent {
          headline: advancedContentHeadline
          introParagraph: advancedContentSectionsIntroParagraph
          columns: advancedContentColumns {
            columnType: advancedColumnType
            title: advancedColumnTitle
            wysiwyg: advancedColumnWysiwyg
            image: advancedColumnImage {
              sourceUrl
              relativePath
            }
            videoEmbed
            youtubeEmbed: advancedYoutubeEmbed
            buttonLink: advancedColumnButtonLink {
              target
              title
              url
            }
            linkType: advancedColumnLinkType # External Link
            buttonText: advancedColumnButtonText
            internalLink: advancedColumnInternalButtonLink {
              ... on WpPage {
                uri
              }
            }
            blogExcerpt: advancedColumnBlogExcerpt {
              ... on Post {
                date
                excerpt
                title
                slug
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                categories {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
            pests: advancedColumnsPests {
              ... on Pest {
                id
                title
                slug
                featuredImage {
                  node {
                    sourceUrl
                    relativePath
                  }
                }
              }
            }
            cards: advancedContentCards {
              card: advancedContentCard {
                content: advancedContentCardContent
                headline: advancedContentCardHeadline
                image: advancedContentCardImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }

  fragment FeaturedSection on Page {
    featuredSection {
      sections {
        appearance: featuredsectionappearance {
          backgroundColor: featuredSectionBackgroundColor
          headlineColor
          imageColumn
          textColor
        }
        sectionContent: featuredSectionContent {
          headline: featuredSectionHeadline
          content: featuredSectionContent
          images: featuredSectionImages {
            image: featuredSectionImage {
              sourceUrl
            }
            colSpan: featuredsectionColspan
          }
        }
      }
    }
  }

  fragment ContentCards on Page {
    contentCards {
      backgroundColor: contentCardsBackgroundColor
      headline: contentCardsHeadline
      shortDescription: contentCardsShortDescription
      cardAppearance {
        contentColor: cardContentColor
        backgroundColor: cardsBackgroundColor
        headlineColor: cardsHeadlineColor
        outlineColor: cardsOutlineColor
      }
      contentCards {
        content: cardContent
        headline: cardHeadline
        image: cardImage {
          sourceUrl
        }
      }
    }
  }

  fragment CooksGuarentee on Page {
    cooksGuarentee {
      content: cooksGuarenteeContent
      image: cooksGuarenteeImage {
        sourceUrl
      }
      appearance: cooksGuarenteeAppearance {
        backgroundColor: cooksGuarenteeBackgroundColor
        textColor: cooksGuarenteeTextColor
      }
    }
  }

  fragment FaqSection on Page {
    faqSection {
      headline: faqSectionHeadline
      questions: faqSectionList {
        answer: faqSectionAnswer
        question: faqSectionQuestion
      }
    }
  }

  fragment CallToAction on Page {
    callToAction {
      headline: ctaComponentHeadline
      subHeadline: ctaComponentSubHeadline
      paragraph: ctaComponentParagraph
      ctaButton: ctaComponentButton {
        addChevron
        buttonColor: ctaComponentButtonColor
        buttonOutline: ctaComponentButtonOutline
        buttonText: ctaComponentButtonText
        textColor: ctaComponentTextColor
        linkType: ctaLinkType
        buttonLink: ctaComponentLink {
          ... on Page {
            uri
          }
        }
        externalLink: ctaExternalLink {
          target
          title
          url
        }
      }
    }
  }

  fragment EmployeeTraining on Page {
    employeeTraining {
      hero: employeeTrainingHero {
        headline: employeeTrainingHeroHeadline
        paragraph: employeeTrainingHeroParagraph
        sectionLabel: employeeTrainingHeroSectionLabel
        cooksAcademyImage: employeeTrainingHeroCooksAcademyImage {
          sourceUrl
        }
        background: employeeTrainingHeroBackgroundImage {
          sourceUrl
        }
      }
      ctaText: employeeTrainingCtaText
      cooksDifferenceSection: employeeTrainingCooksDifferenceSection {
        headline: cooksDifferenceSectionHeadline
        subtext: cooksDifferenceSectionParagraph
        sectionLabel: cooksDifferenceSectionSectionLabel
        video: cooksDifferenceSectionCooksDifferenceVideo {
          embed
          poster {
            sourceUrl
          }
        }
      }
      onSiteTrainingSection: employeeTrainingOnSiteTrainingSection {
        headline: onSiteTrainingSectionHeadline
        subtext: onSiteTrainingSectionSubtext
        sectionLabel: onSiteTrainingSectionSectionLabel
        imageGroup: onSiteTrainingImageList {
          image: onSiteTrainingPhoto {
            sourceUrl
          }
          colspan: onSiteTrainingColumnSpan
        }
        images: onSiteTrainingSectionImages {
          image: onSiteTrainingSectionImagesImage {
            sourceUrl
          }
          colspan: onSiteTrainingSectionImagesColspan
        }
        content: onSiteTrainingSectionContent
      }
      commitmentSection: employeeTrainingCommitmentSection {
        headline: commitmentSectionHeadline
        paragraph: commitmentSectionParagraph
        images: commitmentSectionImages {
          image: commitmentSectionImagesImage {
            sourceUrl
          }
          colspan
        }
        sectionLabel: commitmentSectionSectionLabel
        content: commitmentSectionContent
      }
      careersSection: employeeTrainingCareers {
        headline
        paragraph
        benefitsHeadline
        benefits
        opportunityStatement
      }
      footerCta: employeeTrainingFooterCta {
        headline: footerCtaHeadline
        paragraph: footerCtaParagraph
        sectionLabel: footerCtaSectionLabel
      }
      mainCareersLink
    }
  }
  fragment CookMuseum on Page {
    cookMuseum {
      iconBoxes {
        title
        paragraph
        icon {
          sourceUrl
        }
      }
    }
  }

  fragment PestControlServices on Page {
    pestControlServices {
      hero {
        headline: pestControlServicesHeroHeadline
        subHeadline: pestControlServicesHeroSubHeadline
        paragraph: pestControlServicesHeroParagraph
        ctaButtonText: pestControlServicesHeroCtaButtonText
        background {
          sourceUrl
        }
      }
      ppsMaxSection {
        title: ppsMaxSectionServiceTitle
        frequencyOfService: ppsMaxSectionFrequencyOfService
        subHeadline: ppsMaxSectionSubHeadline
        content: ppsMaxSectionContent
        footnote: ppsMaxSectionFootnote
      }
      interiorTreatmentSection {
        footnote: interiorTreatmentSectionFootnote
        list: interiorTreatmentList {
          step: interiorTreatmentListStep
        }
      }
      exteriorTreatmentSection {
        list: exteriorTreatmentList {
          step: exterirorTreatmentListStep
        }
      }
      diagrams {
        interior {
          floorplan {
            sourceUrl
          }
          roof {
            sourceUrl
          }
        }
        exterior {
          sourceUrl
        }
      }
      numberedProcess {
        processStep {
          headline
          subtext
          image {
            sourceUrl
          }
        }
      }
      seasonalPestFocus {
        season {
          headline
          subtext
          graphic {
            sourceUrl
          }
        }
      }
      faq {
        headline
        questionList {
          question
          answer
        }
      }
      footerCta {
        headline
        subtext
        buttonText
      }
    }
  }

  fragment About on Page {
    about {
      missionHeadline
      missionSubheading
      missionParagraph
      missionImages {
        image {
          sourceUrl
        }
        colspan
      }
      positionHeadline
      positionParagraph
      positionBlocks {
        image {
          sourceUrl
        }
        title
        paragraph
      }
      integrityHeadline
      integrityParagraph
      integrityBlocks {
        icon {
          sourceUrl
        }
        title
        paragraph
      }
    }
  }

  fragment SpecializedPestServices on Page {
    specializedPests {
      hero: specializedPestServicesHero {
        headline: specializedPestServicesHeadline
        paragraph: specializedPestServicesHeroParagraph
        cta: specializedPestServicesHeroCta {
          title
          url
        }
        background: specializedPestHeroBackground {
          sourceUrl
        }
      }
      footerCta: specializedPestServicesFooterCta {
        headline: specializedPestServicesFooterCtaHeadline
        paragraph: specializedPestServicesFooterCtaParagraph
        ctaButtonText: specializedPestServicesFooterCtaButtonText
      }
      specializedPests {
        pestHeadline
        shortDescription
        pest {
          ... on Pest {
            id
            title
            slug
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }

  fragment CookieTheCop on Page {
    cookieTheCop {
      heroHeadline
      heroParagraph
    }
  }

  fragment CompanyHistory on Page {
    companyHistory {
      timelineSlides {
        year
        columns {
          title
          paragraph
          imagePosition
          image {
            sourceUrl
            sizes
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
  }

  fragment HomeExplorer on Page {
    homeExplorer {
      hero: homeExplorerHero {
        headline: homeExplorerHeroHeadline
        paragraph: homeExplorerHeroParagraph
      }
      homeLayers: homeExplorerHomeLayers {
        label: homeExplorerHomeLayerLabel
        graphic: homeExplorerGraphic {
          sourceUrl
        }
        descriptionItems: homeExplorerDescriptionItems {
          headline: homeExplorerDescriptionHeadline
          paragraph: homeExplorerDescriptionParagraph
          xPos: descriptionXPosition
          yPos: homeExplorerYPosition
        }
      }
      footerCta: homeExplorerFooterCta {
        ctaButtonText: homeExplorerFooterCtaButtonText
        headline: homeExplorerFooterCtaHeadline
        paragraph: homeExplorerFooterCtaParagraph
      }
    }
  }

  fragment BuilderServices on Page {
    builderServices {
      useCommercialHeader
      hero: builderServicesHero {
        headline: builderServicesHeadline
        subHeadline: builderServicesSubHeadline
        subtext: builderServicesSubtext
        ctaButtonText: builderServicesCtaButtonText
        url: builderServicesHeroUrl {
          ... on Page {
            id
            slug
            link
            uri
          }
        }
        background: builderServicesBackground {
          sourceUrl
        }
      }
      cardGroup: builderServicesCardGroup {
        cards: builderServicesCards {
          headline: builderServicesCardHeadline
          content: builderServicesCardContent
          image: builderServicesCardImage {
            sourceUrl
          }
        }
      }
      footerCta: builderServicesFooterCta {
        headline: builderServicesFooterHeadline
        subtext: builderServicesFooterSubtext
        ctaButtonText: builderServicesFooterCtaButtonText
        url: builderServicesFooterUrl {
          ... on Page {
            id
            slug
            link
            uri
          }
        }
      }
    }
  }

  fragment ResidentialTermiteServices on Page {
    residentialTermiteServices {
      hero: residentialTermiteServicesHero {
        headline: residentialTermiteServicesHeroHeadline
        subtext: residentialTermiteServicesHeroSubtext
        subtitle: residentialTermiteServicesHeroSubtitle
        ctaButtonText: residentialTermiteServicesHeroCtaButtonText
      }
      residentialTermiteServicesBadge {
        sourceUrl
      }
      sentriconHouseSection {
        slides {
          content
        }
      }
      sentriconVideoSection {
        video
        poster {
          altText
          sourceUrl
        }
        headline
        content: termiteServicesContent
      }
      faq: residentialTermiteServicesFaq {
        headline: residentialTermiteServicesFaqHeadline
        questionList: residentialTermiteServicesFaqQuestionList {
          question: residentialTermiteServicesQuestion
          answer: residentialTermiteServicesAnswer
        }
      }
      footerCta: residentialTermiteServicesFooterCta {
        headline: residentialTermiteServicesFooterCtaHeadline
        paragraph: residentialTermiteServicesFooterCtaParagraph
        ctaButtonText: residentialTermiteServicesFooterCtaButtonText
      }
    }
  }
  fragment CommercialTermiteServices on Page {
    commercialTermiteServices {
      hero: commercialTermitesHero {
        headline: commercialTermitesHeroHeadline
        subHeadline: commercialTermitesHeroSubHeadline
        paragraph: commercialTermitesHeroParagraph
        ctaButtonText: commercialTermitesHeroCtaButtonText
      }
      slides: commercialTermitesSlides {
        content: comercialTermitesSlideContent
      }
      sentriconVideoSection: commercialTermitesSentriconVideoSection {
        headline: commercialTermitesVideoHeadline
        embed: commercialTermitesVideoEmbed
        poster: commercialTermitesVideoPoster
        content: commercialTermitesVideoContent
      }
      faq: commercialTermitesFaq {
        headline: commercialTermitesFaqHeadline
        questionList: commercialTermitesFaqQuestionsList {
          answer: commercialTermitesAnswer
          question: commercialTermitesQuestion
        }
      }
      footerCta: commercialTermitesFooterCta {
        headline: commercialTermitesFooterHeadline
        paragraph: commercialTermitesFooterParagraph
        ctaButtonText: commercialTermitesFooterCtaButtonText
      }
    }
  }

  fragment HomePage on Page {
    hero: homeHero {
      crown
      headline
      subtext
      cta {
        title
        url
        target
      }
      backgroundImage {
        sourceUrl
      }
    }
    services: homeServices {
      headline: servicesHeadline
      paragraph: servicesParagraph
      services {
        image {
          sourceUrl
        }
        headline
        link {
          url
        }
      }
    }
    difference: homeDifference {
      headline: differenceHeadline
      paragraph: differenceParagraph
      image: differenceFeaturedImage {
        sourceUrl
      }
    }
    satisfaction: homeSatisfaction {
      headline: satisfactionHeadline
      paragraph: satisfactionParagraph
    }
  }

  fragment CommercialIndustryPreview on CommercialIndustry {
    slug
    title
    id
    featuredImage {
      node {
        sourceUrl
      }
    }
    commercialIndustries {
      subIndustries {
        heroTitle: subindustryHeroTitle
        heroDescription: subindustryHeroDescription
        subHeroHeadline: subIndustrySubHeroHeadline
        title
        description
      }
      industryRelatedPests {
        ... on Pest {
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }

  fragment CommercialServices on Page {
    commercialServices {
      hero {
        paragraph
        headline
        ctaButton {
          title
          url
        }
        background {
          sourceUrl
        }
      }
      footerCta {
        ctaButton {
          title
          url
        }
        headline
        paragraph
      }
      cardGroup {
        headline
        paragraph
        cards {
          headline
          paragraph
          icon {
            sourceUrl
          }
        }
      }
      industriesHeading {
        headline
        paragraph
      }
    }
  }
`
