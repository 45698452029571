import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import { H1 } from '../../components/Headings'
import Container from '../../components/Container'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../../helpers'

const PageTemplate = ({ data, location, preview }) => {
  const page = preview ? preview : data.allWpPage.nodes[0]

  return (
    <Layout location={location}>
      {!preview && (
        <Seo
          title={`${page.title} - ${JSON.parse(
            data.wp.seo.contentTypes.post.schema.raw
          )['@graph'][0]?.name.replace('&#039;', "'")}`}
          postSchema={formatYoastSeo(data.wp.seo.contentTypes.post.schema).raw}
        />
      )}
      <Container tw="py-12">
        <H1>{page.title}</H1>
        <div
          tw="prose max-w-full"
          dangerouslySetInnerHTML={{ __html: page.content }}
        >
          {/* {contentParser({ content: page.content }, { wordPressUrl: process.env.GATSBY_WP_BASE_URL, uploadsUrl: `${process.env.GATSBY_WP_BASE_URL}/app/uploads/` })} */}
        </div>
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    wp {
      ...WpSeoSchema
    }
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        ...HeroSection
        ...CallToAction
        title
        content
      }
    }
  }
`
