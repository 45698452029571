import React from 'react'
import { graphql } from 'gatsby'
import contentParser from 'gatsby-wpgraphql-inline-images'
import Image from 'gatsby-image'

import Layout from '../components/Layout'
import { H1 } from '../components/Headings'
import Container from '../components/Container'
import 'twin.macro'
import PostContent from '../components/Post/Content'
import PostMetaContent from '../components/Post/MetaContent'
import CategoryNav from '../components/Post/CategoryNav'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import FooterCTA from '../components/FooterCTA'
import { formatYoastSeo } from '../helpers'

const PostTemplate = ({ data, preview, location }) => {
  const page = data?.wpPost
  const previewPage = preview?.revision ? preview.revision : preview

  const pageData = previewPage ? previewPage : page

  const { title, content, postCategories, featuredImage, date: dateString } =
    previewPage || page

  return (
    <Layout location={location}>
      {!preview && <Seo title={title} post={formatYoastSeo(pageData)} />}

      <main>
        <Container tw="pt-10 md:(grid grid-cols-4 gap-8)">
          {/* Sticky Categories Nav */}

          <div tw="h-full relative mb-8 md:(mb-0)">
            <CategoryNav />
          </div>

          {/* Article */}
          <div aria-label="article" tw="md:(col-span-3)">
            <div aria-label="article">
              <H1 tw="text-gray-800 font-bold">{title}</H1>
              <PostMetaContent
                publishedDate={dateString}
                categories={postCategories.nodes}
              />
              {featuredImage?.node?.localFile ? (
                <Image
                  fluid={featuredImage.node.localFile.childImageSharp.fluid}
                  tw="w-full rounded-lg mb-5"
                />
              ) : (
                <img
                  tw="w-full mb-5 rounded-lg"
                  src={featuredImage?.node?.sourceUrl}
                  alt=""
                  loading="lazy"
                />
              )}
              <PostContent tw="text-xl text-gray-700 leading-relaxed">
                {contentParser(
                  { content },
                  {
                    wordPressUrl: process.env.GATSBY_WP_BASE_URL,
                    uploadsUrl: `${process.env.GATSBY_WP_BASE_URL}/apps/uploads`,
                  }
                )}
              </PostContent>
            </div>
          </div>
        </Container>
      </main>

      {/* Footer */}
      <FooterCTA
        headline="You Don't Have to live with Pests."
        paragraph="Follow the lead of more than 300,000 Southern homeowners who trust Cook’s Pest Control to help protect their homes from household pests."
        ctaText="Get a Quote"
      />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      seo {
        ...PostSEO
      }
      id
      content
      postCategories: categories {
        nodes {
          id
          slug
          name
        }
      }
      date
      title
      ...FeaturedPostImage
    }
    wp {
      ...WpSeoSchema
    }
  }
`

export default PostTemplate
