import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw, { css } from 'twin.macro'

import Layout from '../../components/Layout'
import { H1 } from '../../components/Headings'
import Hero from '../../components/Hero'
import Container from '../../components/Container'
import { ButtonA } from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../../helpers'

const PageTemplate = ({ data, location, preview }) => {
  const page = preview ? preview : data.allWpPage.edges[0].node
  const {
    title,
    content,
    featuredImage,
    cookMuseum: { iconBoxes },
  } = page

  return (
    <>
      <Layout location={location}>
        <Seo post={formatYoastSeo(page)} />
        <Hero
          background={
            featuredImage?.node?.localFile?.publicURL ||
            featuredImage?.node?.sourceUrl
          }
        >
          <div tw="text-center py-16 md:(py-32)">
            <H1 tw="mb-8 text-white">{title}</H1>
            <ButtonA href="https://www.cookmuseum.org/" large>
              Learn More <FontAwesomeIcon tw="ml-3" icon={faSignOutAlt} />
            </ButtonA>
          </div>
        </Hero>
        <Container tw="py-0 md:(py-16) text-center prose-xl">
          <div
            css={[
              tw`text-gray-700 text-base leading-relaxed mt-6 text-center md:(mt-0)`,
              css`
                @media (max-width: 768px) {
                  & h2 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: black;
                  }
                }
                @media (min-width: 768px) {
                  & h2 {
                    font-weight: bold;
                    color: black;
                  }
                }
              `,
            ]}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Container>
        <Container tw="pb-8 md:(grid grid-cols-3 col-gap-32)">
          {iconBoxes.map(({ title, paragraph, icon }) => (
            <div tw="text-center flex flex-col items-center pt-4 mb-6 md:(mb-0)">
              <div tw="bg-yellow-600 flex items-center justify-center h-32 w-32 rounded-full mb-4">
                <img
                  src={icon?.localFile?.publicURL || icon?.sourceUrl}
                  loading="lazy"
                />
              </div>
              <h3 tw="text-2xl">{title}</h3>
              <p tw="text-gray-700 text-base">{paragraph}</p>
            </div>
          ))}
        </Container>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          seo {
            ...PostSEO
          }
          title
          content
          ...FeaturedImage
          cookMuseum {
            iconBoxes {
              title
              paragraph
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`
