import { graphql } from 'gatsby'
import React from 'react'
import 'twin.macro'

import Form from '../../components/Form'
import Layout from '../../components/Layout'

import Seo from 'gatsby-plugin-wpgraphql-seo'
import Container from '../../components/Container'
import { formatYoastSeo } from '../../helpers'
import { useFormData } from '../../hooks/gravityForms'

const PageTemplate = ({ data, location, preview }) => {
  const page = preview ? preview : data.allWpPage.nodes[0]

  const allFormData = useFormData()

  const handleError = ({ values, error, reset }) => {
    //handle error
  }

  const handleSuccess = ({ values, reset, confirmations }) => {
    //handle success
  }

  return (
    <Layout headerType="commercial" location={location}>
      {!preview && <Seo post={formatYoastSeo(page)} />}
      <Container tw="py-8">
        <Form
          id={2}
          allFormData={allFormData}
          successCallback={handleSuccess}
          errorCallback={handleError}
        />
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
        seo {
          ...PostSEO
        }
      }
    }
  }
`
