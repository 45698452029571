/*  https://justinwhall.com/live-previews-with-wordpress-gatsby/
 */

import React from 'react'
import { Query } from 'react-apollo'
import queryString from 'query-string'
import tw from 'twin.macro'
import { H1 } from '../Headings'
import Container from '../Container'

const Code = tw.span`rounded bg-purple-200 text-purple-700 border-purple-400 border-2 px-3 py-1 mx-2 italic`

const withPreview = (args = { preview: false }) => Component => {
  const preview = props => {
    const parsed = queryString.parse(props.location.search)
    const { nonce, preview, post } = parsed

    // Id needs to be an int for preview query.
    const id = parseInt(post, 10)

    /**
     * If no preview param, return the component with the preview props as false.
     */
    if (!preview) {
      return <Component preview={false} {...props} />
    }

    /**
     * Otherwise, run our Apollo query.
     */
    return (
      <Query
        query={args.preview}
        variables={{
          id,
          nonce,
        }}
      >
        {({ data, loading, error }) => {
          if (loading)
            return (
              <Container>
                <div tw="animate-pulse bg-blue-100 border border-blue-400 rounded p-5 m-10">
                  <p tw="font-bold text-2xl">Loading preview...</p>
                </div>
              </Container>
            )
          if (error)
            return (
              <Container>
                <div tw="bg-red-100 border border-red-500 rounded p-5 m-10">
                  <H1 tw="text-2xl">Error</H1>
                  <p tw="mb-3">{error.message}</p>
                  <p>
                    Check if <Code>nonce</Code> has been set in cookies.
                  </p>

                  <div tw="bg-yellow-100 border border-yellow-400 p-5 rounded mt-10 overflow-x-hidden hocus:(overflow-x-scroll)">
                    <pre>
                      {JSON.stringify({ post, nonce, preview, error }, null, 2)}
                    </pre>
                  </div>
                </div>
              </Container>
            )
          return <Component {...props} previewData={data} />
        }}
      </Query>
    )
  }

  return preview
}

export default withPreview
