import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { H3 } from '../Headings'
import { ALink } from '../A'
import tw from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronSquareUp } from '@fortawesome/pro-solid-svg-icons'

const CategoryNav = ({ currentCategory }) => {
  const [categoriesOpen, setCategoriesOpen] = useState(false)

  const data = useStaticQuery(graphql`
    {
      allWpCategory(filter: { name: { ne: "Uncategorized" } }) {
        edges {
          node {
            name
            slug
            featuredImage
          }
        }
      }
    }
  `)

  const categories = data.allWpCategory.edges

  return (
    <nav
      aria-label="Categories"
      tw="flex flex-col leading-relaxed top-0 md:(sticky border-0) border-b"
    >
      <div
        tw="flex items-center justify-between"
        onClick={() => setCategoriesOpen(!categoriesOpen)}
      >
        <H3 tw="text-2xl mb-3">Categories</H3>
        <div tw="bg-gray-900 h-4">
          <FontAwesomeIcon
            icon={faChevronSquareUp}
            css={[
              tw`md:(hidden) transform rotate-180 text-yellow-600 -mt-2 -ml-1 text-3xl mb-2`,
              categoriesOpen && tw`transform rotate-0`,
            ]}
          />
        </div>
      </div>
      <ol>
        <div
          css={[
            tw`bg-gray-100 overflow-hidden sm:(overflow-visible)`,
            !categoriesOpen && tw`h-0`,
          ]}
        >
          <div tw="p-4 grid grid-cols-2 gap-1 md:(gap-2) md:(flex flex-col) md:(h-auto)">
            {categories.map(({ node: { name, slug, featuredImage } }) => (
              <li>
                <ALink
                  css={[
                    tw`h-8 flex gap-4 leading-6 text-blue-600 content-center items-center text-xs md:(text-lg leading-none) no-underline hover:(underline) capitalize mb-3`,
                    slug === currentCategory &&
                      tw`font-bold bg-white rounded-full`,
                  ]}
                  to={`/learn/blog/category/${slug}`}
                >
                  <div tw="flex content-center items-center ml-2 w-6 h-6">
                    <img src={featuredImage} loading="lazy" />
                  </div>
                  <div tw="flex content-center pt-1 md:(pt-0)">{name}</div>
                </ALink>
              </li>
            ))}
          </div>
        </div>
      </ol>
    </nav>
  )
}

CategoryNav.defaultProps = {
  currentCategory: null,
}

export default CategoryNav
