import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'
import Image from 'gatsby-image'

import Layout from '../../components/Layout'
import Container from '../../components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPauseCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import Hero from '../../components/Hero'
import jingleAudio from '../../../static/jingle.mp3'
import useSound from 'use-sound'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../../helpers'
import useLookieLookie from '../../hooks/useLookieLookie'

const JingleAudioPlayer = () => {
  const [play, { stop, isPlaying }] = useSound(jingleAudio)

  const handleClick = () => (isPlaying ? stop() : play())

  const lookieLookie = useLookieLookie()

  return (
    <div tw="flex items-center">
      <FontAwesomeIcon
        icon={isPlaying ? faPauseCircle : faPlayCircle}
        size="3x"
        tw="text-yellow-600 mr-8 hover:(text-yellow-400) focus:(text-yellow-400) active:(text-yellow-500) cursor-pointer"
        onClick={handleClick}
      />
      <Image
        fixed={lookieLookie.nodes[0].childImageSharp.fixed}
        imgStyle={{ objectFit: 'contain' }}
      />
    </div>
  )
}

const PageTemplate = ({ data, location, preview }) => {
  const page = preview ? preview : data.allWpPage.edges[0].node
  const {
    title,
    content,
    featuredImage,
    cookieTheCop: { heroHeadline, heroParagraph },
  } = page

  return (
    <Layout location={location}>
      <Seo post={formatYoastSeo(page)} />
      <Hero
        css={['background: rgba(255,255,255,0.85);']}
        background={
          featuredImage?.node?.localFile?.publicURL ||
          featuredImage?.node?.sourceUrl
        }
      >
        <div tw="py-16 md:(w-1/2 py-32)">
          <p tw="text-5xl font-bold">{heroHeadline}</p>
          <p tw="text-lg text-gray-800 leading-loose">{heroParagraph}</p>
        </div>
      </Hero>
      <section aria-label="">
        <Container tw="py-16">
          <JingleAudioPlayer />
          <div
            tw="prose max-w-full"
            dangerouslySetInnerHTML={{ __html: content }}
          >
            {/* {contentParser({ content: content }, { wordPressUrl: `http://wp-qa.cookspest.alloy.services/`, uploadsUrl: `http://wp-qa.cookspest.alloy.services/app/uploads/` })} */}
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          seo {
            ...PostSEO
          }
          title
          content
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          cookieTheCop {
            heroHeadline
            heroParagraph
          }
        }
      }
    }
  }
`
